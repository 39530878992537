import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Headline from "../atoms/Headline"
import Text from "../atoms/Text"

import { useState } from "react"
import CirclesSVG from "../../images/services-circles.svg"
import { useEffect } from "react"

const ServicesSection = styled.section`
  padding: 0 20px;
  margin: 150px 0;
`

const ServicesContainer = styled.div`
  position: relative;
  @media (min-width: 768px) {
    max-width: 720px;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .circles_top_left {
    width: 85px;
    position: absolute;
    top: -5%;
    left: 5%;

    @media (min-width: 1200px) {
      width: 120px;
    }
  }
`

const HeadlineWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    max-width: 420px;
    margin: 20px 0 60px 0;
  }
`

const StyledHeadline = styled(Headline)`
  color: ${({ theme }) => theme.dark};

  .services_headline--red {
    color: ${({ theme }) => theme.red};
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.isVisible {
    display: none;
  }

  &.isVisibleSecond {
    display: none;
  }
`

const Block = styled(Link)`
  position: relative;
  width: 230px;
  height: 230px;
  border-bottom: 2px solid ${({ theme }) => theme.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  margin: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);

    .gatsby-image-wrapper {
      opacity: 0.5;
    }
  }

  @media (min-width: 1200px) {
    width: 290px;
    height: 290px;
  }
`

const Title = styled(Text)`
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.bodyS};
  color: ${({ theme }) => theme.dark};
  text-align: center;

  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.bodyM};
  }
`

const BlockImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.12;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.gatsby-image-wrapper {
    position: absolute !important;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 32px;
  background: #f03600;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`

const Input = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + ${Switch} {
    background: green;
    &:before {
      transform: translate(26px, -50%);
    }
  }
`

const ServicesNext = () => {
  const [isPPOZ, setIsPPOZ] = useState(false)
  const [ppozItems, setPpozItems] = useState([])
  const [bhpItems, setBhpItems] = useState([])
  const data = useStaticQuery(query)

  const items = data.datoCmsMainPage.services
  const filterPpozItems = () => {
    const result = items.filter(item => item.category.includes("ppoz"))
    setPpozItems(result)
  }

  const filterBhpItems = () => {
    const result = items.filter(item => item.category.includes("bhp"))
    setBhpItems(result)
  }

  useEffect(() => {
    filterPpozItems()
    filterBhpItems()
  }, [])

  console.log(ppozItems)

  const handleChange = e => setIsPPOZ(e.target.checked)
  return (
    <ServicesSection id="services">
      <ServicesContainer className={isPPOZ && "isVisible"}>
        <img src={CirclesSVG} alt="" className="circles_top_left" />
        <HeadlineWrapper className="services_animations--trigger">
          <StyledHeadline
            as="h2"
            data-aos="fade-down"
            data-aos-anchor=".services_animations--trigger"
          >
            Ochrona{" "}
            <span className="services_headline--red">przeciwpożarowa</span>
          </StyledHeadline>
          <Text
            $isDark
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-anchor=".services_animations--trigger"
          >
            Sprawdź co możemy dla Ciebie zrobić z zakresu ochrony
            przeciwpożarowej.
          </Text>
        </HeadlineWrapper>
        <SwitchContainer>
          <Label>
            <span>Wybrano: {isPPOZ ? "BHP" : "PPOŻ"}</span>
            <Input checked={isPPOZ} type="checkbox" onChange={handleChange} />
            <Switch />
          </Label>
        </SwitchContainer>

        {/* Usługi PPOŻ */}
        <BlocksWrapper className={isPPOZ && "isVisible"}>
          {ppozItems.map((item, id) => (
            <Block to={item.link} key={id}>
              <BlockImage image={item.image.gatsbyImageData} alt="" />
              <Title>{item.header}</Title>
            </Block>
          ))}
        </BlocksWrapper>

        {/* Usługi BHP */}
        <BlocksWrapper className={!isPPOZ && "isVisibleSecond"}>
          {bhpItems.map((item, id) => (
            <Block to={item.link} key={id}>
              <BlockImage image={item.image.gatsbyImageData} alt="" />
              <Title>{item.header}</Title>
            </Block>
          ))}
          {/* <Block to={data.szkoleniaBhpSlug.slug}>
            <BlockImage
              image={data.szkoleniaBhp.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.szkoleniaBhpSlug.pageTitle}</Title>
          </Block> */}
        </BlocksWrapper>
      </ServicesContainer>
    </ServicesSection>
  )
}

const query = graphql`
  {
    datoCmsMainPage(services: { elemMatch: { category: { in: ["ppoz"] } } }) {
      services {
        category
        header
        image {
          gatsbyImageData
        }
        link
      }
    }
  }
`

export default ServicesNext
