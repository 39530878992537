import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Headline from "../atoms/Headline"
import Text from "../atoms/Text"

import { useState } from "react"
import CirclesSVG from "../../images/services-circles.svg"

const ServicesSection = styled.section`
  padding: 0 20px;
  margin: 150px 0;
`

const ServicesContainer = styled.div`
  position: relative;
  @media (min-width: 768px) {
    max-width: 720px;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .circles_top_left {
    width: 85px;
    position: absolute;
    top: -5%;
    left: 5%;

    @media (min-width: 1200px) {
      width: 120px;
    }
  }
`

const HeadlineWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    max-width: 420px;
    margin: 20px 0 60px 0;
  }
`

const StyledHeadline = styled(Headline)`
  color: ${({ theme }) => theme.dark};

  .services_headline--red {
    color: ${({ theme }) => theme.red};
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.isVisible {
    display: none;
  }

  &.isVisibleSecond {
    display: none;
  }
`

const Block = styled(Link)`
  position: relative;
  width: 230px;
  height: 230px;
  border-bottom: 2px solid ${({ theme }) => theme.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  margin: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);

    .gatsby-image-wrapper {
      opacity: 0.5;
    }
  }

  @media (min-width: 1200px) {
    width: 290px;
    height: 290px;
  }
`

const Title = styled(Text)`
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.bodyS};
  color: ${({ theme }) => theme.dark};
  text-align: center;

  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.bodyM};
  }
`

const BlockImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.12;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.gatsby-image-wrapper {
    position: absolute !important;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 32px;
  background: #f03600;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`

const Input = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + ${Switch} {
    background: green;
    &:before {
      transform: translate(26px, -50%);
    }
  }
`

const Services = () => {
  const [isPPOZ, setIsPPOZ] = useState(false)
  const data = useStaticQuery(blockImg)

  const handleChange = e => setIsPPOZ(e.target.checked)
  return (
    <ServicesSection id="services">
      <ServicesContainer className={isPPOZ && "isVisible"}>
        <img src={CirclesSVG} alt="" className="circles_top_left" />
        <HeadlineWrapper className="services_animations--trigger">
          <StyledHeadline
            as="h2"
            data-aos="fade-down"
            data-aos-anchor=".services_animations--trigger"
          >
            Ochrona{" "}
            <span className="services_headline--red">przeciwpożarowa</span>
          </StyledHeadline>
          <Text
            $isDark
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-anchor=".services_animations--trigger"
          >
            Sprawdź co możemy dla Ciebie zrobić z zakresu ochrony
            przeciwpożarowej.
          </Text>
        </HeadlineWrapper>
        <SwitchContainer>
          <Label>
            <span>Wybrano: {isPPOZ ? "BHP" : "PPOŻ"}</span>
            <Input checked={isPPOZ} type="checkbox" onChange={handleChange} />
            <Switch />
          </Label>
        </SwitchContainer>
        <BlocksWrapper className={isPPOZ && "isVisible"}>
          <Block to={data.instrukcjaPpozSlug.slug}>
            <BlockImage
              image={data.instrukcjaPpoz.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.instrukcjaPpozSlug.pageTitle}</Title>
          </Block>
          <Block to={data.audytPpozSlug.slug}>
            <BlockImage
              image={data.audytPpoz.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>Audyt przeciwpożarowy</Title>
          </Block>
          <Block to={data.operatPpozSlug.slug}>
            <BlockImage
              image={data.operatPpoz.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.operatPpozSlug.pageTitle}</Title>
          </Block>
          <Block to={data.gasniceSlug.slug}>
            <BlockImage
              image={data.gasnice.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.gasniceSlug.pageTitle}</Title>
          </Block>
          <Block to={data.hydrantySlug.slug}>
            <BlockImage
              image={data.hydranty.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.hydrantySlug.pageTitle}</Title>
          </Block>
          <Block to={data.szkoleniaPpozSlug.slug}>
            <BlockImage
              image={data.szkoleniaPpoz.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.szkoleniaPpozSlug.pageTitle}</Title>
          </Block>
          <Block to={data.szkoleniaPomocSlug.slug}>
            <BlockImage
              image={data.szkoleniaPomoc.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.szkoleniaPomocSlug.pageTitle}</Title>
          </Block>
          <Block to={data.planySlug.slug}>
            <BlockImage
              image={data.planyEwakuacyjne.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.planySlug.pageTitle}</Title>
          </Block>
          <Block to={data.nadzorSlug.slug}>
            <BlockImage
              image={data.nadzor.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.nadzorSlug.pageTitle}</Title>
          </Block>
          <Block to={data.przegladPrzelacznikaSlug.slug}>
            <BlockImage
              image={data.przegladPrzelacznika.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.przegladPrzelacznikaSlug.pageTitle}</Title>
          </Block>
        </BlocksWrapper>
        <BlocksWrapper className={!isPPOZ && "isVisibleSecond"}>
          <Block to={data.szkoleniaBhpSlug.slug}>
            <BlockImage
              image={data.szkoleniaBhp.childImageSharp.gatsbyImageData}
              alt=""
            />
            <Title>{data.szkoleniaBhpSlug.pageTitle}</Title>
          </Block>
        </BlocksWrapper>
      </ServicesContainer>
    </ServicesSection>
  )
}

const blockImg = graphql`
  {
    instrukcjaPpoz: file(name: { eq: "instrukcja-ppoz" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    audytPpoz: file(name: { eq: "audyt-ppoz" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    operatPpoz: file(name: { eq: "operat-ppoz" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    gasnice: file(name: { eq: "gasnice" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    hydranty: file(name: { eq: "hydranty" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    szkoleniaPpoz: file(name: { eq: "szkolenia-ppoz" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    szkoleniaPomoc: file(name: { eq: "szkolenia-z-pierwszej-pomocy" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    planyEwakuacyjne: file(name: { eq: "plany-ewakuacyjne" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    nadzor: file(name: { eq: "nadzor" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    szkoleniaBhp: file(name: { eq: "szkolenia-bhp" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    przegladPrzelacznika: file(name: { eq: "przeglad-przelacznika" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, quality: 100)
      }
    }
    instrukcjaPpozSlug: datoCmsService(originalId: { eq: "56994376" }) {
      slug
      pageTitle
    }
    audytPpozSlug: datoCmsService(originalId: { eq: "57886168" }) {
      slug
      pageTitle
    }
    operatPpozSlug: datoCmsService(originalId: { eq: "58598018" }) {
      slug
      pageTitle
    }
    gasniceSlug: datoCmsService(originalId: { eq: "58598132" }) {
      slug
      pageTitle
    }
    hydrantySlug: datoCmsService(originalId: { eq: "58598065" }) {
      slug
      pageTitle
    }
    szkoleniaPpozSlug: datoCmsService(originalId: { eq: "58598222" }) {
      slug
      pageTitle
    }
    szkoleniaPomocSlug: datoCmsService(originalId: { eq: "58598288" }) {
      slug
      pageTitle
    }
    planySlug: datoCmsService(originalId: { eq: "58598327" }) {
      slug
      pageTitle
    }
    nadzorSlug: datoCmsService(originalId: { eq: "58598399" }) {
      slug
      pageTitle
    }
    szkoleniaBhpSlug: datoCmsService(
      originalId: { eq: "CmmwqD8nTtqol_QreW1bLQ" }
    ) {
      slug
      pageTitle
    }
    przegladPrzelacznikaSlug: datoCmsService(
      originalId: { eq: "XY9q2sSpS_WcBvLzrpxXuQ" }
    ) {
      slug
      pageTitle
    }
  }
`

export default Services
